@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

#root {
  width: 100vw;
  min-height: 100vh;
}

.input-suffix svg {
  width: 16px;
  height: 16px;
}
